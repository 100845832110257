import externalLinks from './externalLinks';

const links = [
  {
    'headline': 'Account Opening',
    'links': [
      {
        'title': 'Account Minimums',
        'href': externalLinks.accountOpeningManagementSite.accountMinimums
      },
      {
        'title': 'Account Types',
        'href': externalLinks.accountOpeningManagementSite.accountTypes
      },
      {
        'title': 'Application Processing Times',
        'href': externalLinks.accountOpeningManagementSite.applicationProcessingTimes
      },
      {
        'title': 'Joint Account Opening',
        'href': externalLinks.accountOpeningManagementSite.jointAccountOpening
      },
      {
        'title': 'Opening an Additional Account',
        'href': externalLinks.accountOpeningManagementSite.openingAnAdditionalAccount
      },
      {
        'title': 'How Do I Change My Account Type?',
        'href': externalLinks.accountOpeningManagementSite.howChangeMyAccountType
      },
      {
        'title': 'How Do I Reopen My Account?',
        'href': externalLinks.accountOpeningManagementSite.howReopenMyAccount
      },
      {
        'title': 'Credit Check',
        'href': externalLinks.accountOpeningManagementSite.creditCheck
      },
      {
        'title': 'Trusted Contact',
        'href': externalLinks.accountOpeningManagementSite.trustedContact
      },
      {
        'title': 'Account Number Location',
        'href': externalLinks.accountOpeningManagementSite.accountNumberLocation
      },
      {
        'title': 'Required Account Opening Documents (International Accounts)',
        'href': externalLinks.accountOpeningManagementSite.requiredAccountOpeningDocuments
      },
      {
        'title': 'How do I complete and submit a W-8 BEN?',
        'href': externalLinks.accountOpeningManagementSite.howCompleteSubmitW8
      },
      {
        'title': 'Initial Platform Access',
        'href': externalLinks.accountOpeningManagementSite.initialPlatformAccess
      },
      {
        'title': 'Acceptable Visa Types',
        'href': externalLinks.accountOpeningManagementSite.acceptableVisaTypes
      },
      {
        'title': 'Live Quotes',
        'href': externalLinks.accountOpeningManagementSite.liveQuotes
      },
      {
        'title': 'Incomplete Application',
        'href': externalLinks.accountOpeningManagementSite.incompleteApplication
      }
    ]
  },
  {
    'headline': 'Account Management',
    'links': [
      {
        'title': 'Contact Us',
        'href': externalLinks.accountOpeningManagementSite.contactUs
      },
      {
        'title': 'Limited Trading Authorization (LTA)',
        'href': externalLinks.accountOpeningManagementSite.limitedTradingAuthorization
      },
      {
        'title': 'Trade Confirmations & Account Statements',
        'href': externalLinks.accountOpeningManagementSite.tradeConfirmationAccountStatements
      },
      {
        'title': 'Close Account',
        'href': externalLinks.accountOpeningManagementSite.closeAccount
      },
      {
        'title': 'Changing Personal Information',
        'href': externalLinks.accountOpeningManagementSite.changingPersonalInformation
      },
      {
        'title': 'Push & Email Notifications',
        'href': externalLinks.accountOpeningManagementSite.pushAndEmailNotifications
      },
      {
        'title': 'Changing Usernames',
        'href': externalLinks.accountOpeningManagementSite.changingUsernames
      },
      {
        'title': 'Change Email Address',
        'href': externalLinks.accountOpeningManagementSite.changeEmailAddress
      },
      {
        'title': 'Paper Confirmations & Statements',
        'href': externalLinks.accountOpeningManagementSite.paperConfirmationAndStatements
      },
      {
        'title': 'Mutual Fund Liquidation',
        'href': externalLinks.accountOpeningManagementSite.mutualFundLiquidation
      },
      {
        'title': 'Opting Out of Text Notifications',
        'href': externalLinks.accountOpeningManagementSite.optingOutOfTextNotifications
      },
      {
        'title': 'What is my username?',
        'href': externalLinks.accountOpeningManagementSite.whatIsMyUsername
      },
      {
        'title': 'Beneficiaries & Transfer on Death (TOD)',
        'href': externalLinks.accountOpeningManagementSite.beneficiaryAndTransferOnDeath
      },

    ]
  },
  {
    'headline': 'Account Security',
    'links': [
      {
        'title': 'Two-Factor Authentication via Authenticator App',
        'href': externalLinks.accountOpeningManagementSite.twoFactorViaAuthenticator
      },
      {
        'title': 'Two-Factor Authentication via SMS',
        'href': externalLinks.accountOpeningManagementSite.twoFactorViaSMS
      },
      {
        'title': 'Change Password',
        'href': externalLinks.accountOpeningManagementSite.changePassword
      },
      {
        'title': 'Password Reset',
        'href': externalLinks.accountOpeningManagementSite.passwordReset
      },
      {
        'title': 'Confirm Email Address',
        'href': externalLinks.accountOpeningManagementSite.confirmEmailAddress
      },
      {
        'title': 'SIPC Protection',
        'href': externalLinks.accountOpeningManagementSite.SIPCProtection
      },
      {
        'title': 'Security Question',
        'href': externalLinks.accountOpeningManagementSite.securityQuestion
      },
    ]
  },
  {
    'headline': 'Trading Levels Security',
    'links': [
      {
        'title': 'Trading Levels',
        'href': externalLinks.accountOpeningManagementSite.tradingLevels
      },
      {
        'title': 'Upgrade Trading Level',
        'href': externalLinks.accountOpeningManagementSite.upgradeTradingLevels
      },
    ]
  }
]

export default links;